<template>
  <div v-show="showContent">
    <template v-if="game !== undefined">
      <div class="games-pickup-posts-head">
        <h2 class="section__title-mini" style="margin:0">
          <span>今すぐ遊べる{{game}}ゲーマー</span>        
          <i title="" tabindex="0" style="margin-left: 4px; font-size: 20px;" class="fas fa-question-circle game-post__tooltip--game"></i>   
        </h2>
      </div>
    </template>
    <template v-else>
      <div class="pickup-posts-head">
        <h2 class="section__title-mini" style="margin:0">
          <span>今すぐ遊べるゲーマー</span>     
          <i title="" tabindex="0" style="margin-left: 4px; font-size: 20px;" class="fas fa-question-circle game-post__tooltip"></i>   
        </h2>
        <a href="/game_posts" class="pickup-posts-head__link-btn" >もっと見る</a>                
      </div>
    </template>
    <template v-if="game !== undefined" >
      <div  class="game-pickup-posts" :style="{overflowX: overflowX}" >
        <div  class="game-pickup-posts__container" :style="{overflowX: overflowX, width: width}" >
          <div class="pickup-post" v-for="user in users">
            <a class="pickup-post__top" :href="'/profiles/'+user.identified_char+'?game='+game">
              <img class="image" :src="user.image" alt="">
            </a>
            <div class="pickup-post__bottom">
              <p class="pickup-post__title">{{user.name}}</p>
            </div>            
          </div>
        </div>
      </div>
    </template> 
    <template v-else-if="users.length < 13">
      <div  class="oneline-pickup-posts" :style="{overflowX: overflowX}">
        <div  class="oneline-pickup-posts__container" :style="{overflowX: overflowX, width: width}">
          <div class="pickup-post" v-for="user in users">
            <a class="pickup-post__top" :href="'/profiles/'+user.identified_char">
              <img class="image" :src="user.image" alt="">
            </a>
            <div class="pickup-post__bottom">
              <p class="pickup-post__title">{{user.name}}</p>
            </div>            
          </div>
        </div>
      </div>
    </template>    
    <template v-else>
      <div  class="pickup-posts" >
        <div  class="pickup-posts__container" @scroll="onScroll">
          <div class="pickup-post" v-for="user in users">
            <a class="pickup-post__top" :href="'/profiles/'+user.identified_char">
              <img class="image" :src="user.image" alt="">
            </a>
            <div class="pickup-post__bottom">
              <p class="pickup-post__title">{{user.name}}</p>
            </div>            
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeMount, onBeforeUnmount, watch, reactive, getCurrentInstance, inject } from 'vue'
import axios from 'packs/axios'
export default defineComponent({
  props:['pickupPosts', 'game'],
  setup(props) {
    const page = ref(2)
    const users = ref(props.pickupPosts)
    const showContent = ref(false)
    const overflowX = ref("auto")
    const width = ref("100vw")

    onBeforeMount(async () => {
      if(props.pickupPosts.length > 0){
        showContent.value =  true
      }
    })

    onMounted(() => {
      if(users.value.length <= 4){        
        overflowX.value = "hidden"
      }
    })

    const onScroll =(e)=>{
      const { scrollLeft, offsetLeft, scrollWidth, clientWidth } = e.target      
      if (Math.trunc(Math.abs(scrollLeft)) === (scrollWidth - clientWidth)) {
          const params = {
            page: page.value
          }
          let path = '/additional_pickup_post'
          if(props.game !== undefined ){
            path = '/games/additional_pickup_post'
          }

          axios.get(path, {params: params})
          .then((res)=> {
            users.value = [...users.value, ...res.data.pickupPosts] 
            page.value = page.value + 1  
            
          }).catch((err) => { 
            // this.isLoading = false;                 
          })                
      }
    }
    

    return{
      users,
      page,
      width,
      overflowX,
      onScroll,
      showContent
    }
  },
})
</script>


<style lang="scss">
  .pickup-posts{
    display: flex;
    max-width: 1080px;
    margin: auto;
    flex-direction: row;
    height: 35vw;
    max-height: 380px;
    min-height: 260px;
    margin-bottom:12px;
    -webkit-overflow-scrolling: touch
  }
  .pickup-posts__container{
    display: flex;
    flex-direction: column;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    overflow-x: auto;
    margin: 0 auto;
    width: 218vw;
    max-width: 1080px;
    align-content: flex-start;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    -webkit-overflow-scrolling: touch
  }

  .oneline-pickup-posts{
    display: flex;
    max-width: 1080px;
    min-width: 375px; 
    margin: auto;
    flex-direction: row;
    height: 18vw;
    max-height: 200px;
    min-height: 120px;
    margin-bottom:12px;
  }
  .oneline-pickup-posts__container{
    display: flex;
    flex-direction: row;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin: 0 auto;
    max-width: 1080px;
    min-width: 375px; 
    align-content: flex-start;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }

  .game-pickup-posts{
    display: flex;
    max-width: 1050px;
    min-width: 330px;
    margin: auto;
    flex-direction: row;
    height: 18vw;
    max-height: 200px;
    min-height: 120px;
    margin-bottom:12px;
  }
  .game-pickup-posts__container{
    display: flex;
    flex-direction: row;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    margin: 0 auto;
    max-width: 1050px;
    min-width: 330px;
    align-content: flex-start;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
  }

  /* .oneline-pickup-posts__container::-webkit-scrollbar{
    display: none;
  } */
  .pickup-posts::-webkit-scrollbar{
    display: none;
  }
  .pickup-post{
    margin-bottom: 16px;
    height: 14vw;
    width: 12vw;
    min-height: 104px;
    min-width: 87px;
    max-height: 164px;
    max-width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  .image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    border: 2px solid #ED93E3;
  }
  .pickup-post__top{
    min-height: 75px;
    min-width: 75px;
    max-height: 125px;
    max-width: 125px;
    height: 11vw;
    width: 11vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pickup-post__bottom{
    height: auto;
  }
  .pickup-post__title{
    padding-top: 6px;
    text-align: center;
    width: 8vw;
    font-weight: 700;
    font-size: clamp(12px, 1.1vw, 14px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 70px;
    max-width: 130px;
  }
  .games-pickup-posts-head{
    display: flex;
    max-width: 1080px;   
    margin: auto;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 32px;
    position: initial;
    @media screen and (max-width: 412px) {
      position: relative;
    }
  }
  .pickup-posts-head{
    display: flex;
    max-width: 1080px;   
    margin: auto;
    padding-left: 15px;
    padding-right: 6px;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 32px;
  }
  .pickup-posts-head__link-btn{
    height: 38px;
    padding-left: 8px;
    padding-right: 8px;
    background: #FFFFFF;
    border: 2px solid #666666;
    border-radius: 4px;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color:#666666;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
  }

  .game-post__tooltip{
    cursor: pointer;
  }

  .game-post__tooltip {
    position: relative;
    display: inline-flex;
    justify-content: center;
  }

  .game-post__tooltip--game {
    position: relative;
    display: inline-flex;
    justify-content: center;
    @media screen and (max-width: 412px) {
      position: initial;
    }
  }

  .game-post__tooltip:hover::after {
    content: "募集板の投稿の中から今すぐ遊べるゲーマーをピックアップしています";
    position: absolute;
    top: -52px;
    left: -48px;
    z-index: 99;    
    color:white;
    background-color: #555555;
    padding: 8px;
    font-size: 9px;
    border-radius: 6px;
    width: 200px;
    line-height: 14px;
  }

  .game-post__tooltip--game:hover::after {
    content: "募集板の投稿の中から今すぐ遊べるゲーマーをピックアップしています";
    position: absolute;
    top: -52px;
    left: -92px;
    z-index: 99;    
    color:white;
    background-color: #555555;
    padding: 8px;
    font-size: 9px;
    border-radius: 6px;
    width: 200px;
    line-height: 14px;
    @media screen and (max-width: 412px) {
      right: -24px;
      left: initial;
    }    
  }

</style>

