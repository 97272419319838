import { createApp } from 'vue';
import GamePost from '../../components/home/game-post.vue';

document.addEventListener('DOMContentLoaded', () => {
	let templates = document.querySelectorAll("[data-vue='game-post']");
	for (let el of templates) {
		const initialData = JSON.parse(el.getAttribute('data'));
		const app = createApp(GamePost, { pickupPosts: initialData['pickupPosts'], game: initialData['game'] });
		app.mount(el);
	}
});
